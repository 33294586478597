import React, { useState } from 'react'
import clsx from 'clsx'
import { ContainerProps } from '../../../type/ContainerProps'
import { ContainerIcon } from '../../primitive/ContainerIcon/ContainerIcon'
import styles from './ContainerAccordion.module.scss'
import text from '../../../text.json'

interface ContainerAccordionProps {
    container: ContainerProps
    className?: string
}

export const ContainerAccordion = ({
    className,
    container,
}: ContainerAccordionProps) => {
    const [open, setOpen] = useState(false)

    const currentText = text.trashTypes.filter(
        (trash) => trash.id === container.trash_type.id
    )[0]
    const cleaningId = container.cleaning_frequency?.id
    const cleaning =
        cleaningId !== null && cleaningId !== undefined
            ? cleaningId.toString()
            : '00'
    const periodCleaning = cleaning[0]
    const frequencyCleaning = cleaning[1]

    const isDynamicCleaning = container.cleaning_frequency.id === 99

    const getDateTime = (input: string, withTime = true) => {
        const date = new Date(input)
        // check if date is valid
        if (isNaN(date.getTime())) {
            return 'invalid'
        }
        const formattedDate = `${date.getDate()}.${
            date.getMonth() + 1
        }.${date.getFullYear()}`
        const minutes = date.getMinutes()
        const hours = date.getHours()
        const time = `${hours >= 10 ? '' : '0'}${hours}:${
            minutes >= 10 ? '' : '0'
        }${minutes}`

        return withTime ? `${formattedDate} v ${time}` : formattedDate
    }

    return (
        <div className={clsx(styles.wrapper, open && styles.open, className)}>
            <div
                className={styles.head}
                onClick={() => setOpen((prevState) => !prevState)}
            >
                <ContainerIcon className={styles.icon} content={container} />
                <div className={styles.title}>
                    {container.trash_type.description}
                </div>
                <img
                    className={styles.arrow}
                    src="/img/layout/accordion-arrow.svg"
                    alt=""
                />
            </div>
            <div className={styles.body}>
                {container.trash_type.id !== 8 &&
                    container.trash_type.id !== 2 && (
                        <div className={styles.text}>
                            <div className={styles.title}>Vyváženo</div>
                            {isDynamicCleaning ? (
                                <div className={styles.paragraph}>
                                    Dynamicky
                                </div>
                            ) : Number(periodCleaning) === 1 &&
                              Number(frequencyCleaning) !== 1 ? (
                                <div className={styles.paragraph}>
                                    {frequencyCleaning}x týdně - &nbsp;
                                    {container.cleaning_frequency.pick_days}
                                </div>
                            ) : (
                                <>
                                    <div className={styles.paragraph}>
                                        {frequencyCleaning}x za&nbsp;
                                        {periodCleaning}
                                        &nbsp;
                                        {Number(periodCleaning) > 1
                                            ? 'týdny'
                                            : 'týden'}{' '}
                                        -&nbsp;
                                        {container.cleaning_frequency.pick_days}
                                    </div>
                                    {getDateTime(
                                        container.cleaning_frequency.next_pick,
                                        false
                                    ) !== 'invalid' && (
                                        <div className={styles.paragraph}>
                                            Předpokládaný svoz:{' '}
                                            {getDateTime(
                                                container.cleaning_frequency
                                                    .next_pick,
                                                false
                                            )}
                                        </div>
                                    )}
                                </>
                            )}
                        </div>
                    )}
                {container.last_measurement && (
                    <div className={styles.text}>
                        <div className={styles.title}>Zaplnění</div>
                        <div className={styles.paragraph}>
                            {container.last_measurement.percent_calculated}%
                            {getDateTime(
                                container.last_measurement.measured_at_utc
                            ) !== 'invalid' && (
                                <>
                                    {' '}
                                    naměřeno{' '}
                                    {getDateTime(
                                        container.last_measurement
                                            .measured_at_utc
                                    )}
                                </>
                            )}
                        </div>
                    </div>
                )}
                <div className={styles.text}>
                    <div className={styles.title}>Patří sem</div>
                    <div className={styles.paragraph}>
                        {container.trash_type.id === 8 && (
                            <>V uzavřených PET lahvích: </>
                        )}
                        {currentText.Belongs.join(', ')}
                    </div>
                </div>
                <div className={styles.text}>
                    <div className={styles.title}>Nepatří sem</div>
                    <div className={styles.paragraph}>
                        {currentText.NotBelongs.join(', ')}
                    </div>
                </div>
            </div>
        </div>
    )
}
